import { GROUP_LIST, ZONE_LIST, LINE_LIST, STATION_LIST, GET_POPULAR_LINKS, UPDATE_POPULAR_LINKS } from '../constants/apis'
import { encodePostRequestBodyContent } from '../helpers/encryption'
import createAxiosInstance from './createAxiosInstance'
import { successHandler, errorHandler } from './serviceHandlers'
import { NSEWL } from '../constants/values'
import { get } from 'lodash'

export async function getGroupList({ skip = 0, take = 100 }) {
  try {
    const response = await createAxiosInstance(true).get(GROUP_LIST, {
      params: { skip: skip, take: take}
    })
    return successHandler(response)
  } catch (e) {
    return errorHandler(e)
  }
}

export async function getZoneList({ skip = 0, take = 100 }) {
  try {
    const response = await createAxiosInstance(true).get(ZONE_LIST, {
      params: { skip: skip, take: take}
    })
    return successHandler(response)
  } catch (e) {
    return errorHandler(e)
  }
}

export async function getLineList({ skip = 0, take = 100 }) {
  try {
    const response = await createAxiosInstance(true).get(LINE_LIST, {
      params: { skip: skip, take: take}
    })
    return successHandler(response)
  } catch (e) {
    return errorHandler(e)
  }
}

export async function getStationList({ skip = 0, take = 100, lineIds }) {
  try {
    let params = { skip: skip, take: take};
    if (lineIds?.length) params.lineIds = lineIds;
    const response = await createAxiosInstance(true).get(STATION_LIST, {
      params
    })
    return successHandler(response)
  } catch (e) {
    return errorHandler(e)
  }
}

export async function getPopularLinks() {
  try {
    const response = await createAxiosInstance(true).get(GET_POPULAR_LINKS)
    return successHandler(response)
  } catch (e) {
    return errorHandler(e)
  }
}

export async function updatePopularLinks(links = []) {
  try {
    const response = await createAxiosInstance(true).post(UPDATE_POPULAR_LINKS, {
      links: encodePostRequestBodyContent(JSON.stringify(links))
    })
    return successHandler(response)
  } catch (e) {
    return errorHandler(e)
  }
}

export function GetSplitLine(stationName, lineName) {
  if (lineName !== NSEWL)
    return 'CCL';
  const options = [
    { station: 'Admiralty', line: 'NSL' },
    { station: 'Aljunied', line: 'EWL' },
    { station: 'Ang Mo Kio', line: 'NSL' },
    { station: 'Bedok', line: 'EWL' },
    { station: 'Bishan', line: 'NSL' },
    { station: 'Boon Lay', line: 'EWL' },
    { station: 'Braddell', line: 'NSL' },
    { station: 'Bugis', line: 'EWL' },
    { station: 'Bukit Batok', line: 'NSL' },
    { station: 'Bukit Gombak', line: 'NSL' },
    { station: 'Buona Vista', line: 'EWL' },
    { station: 'Canberra', line: 'NSL' },
    { station: 'Changi Airport', line: 'EWL' },
    { station: 'Chinese Garden', line: 'EWL' },
    { station: 'Choa Chu Kang', line: 'NSL' },
    { station: 'City Hall', line: 'NSEWL' },
    { station: 'Clementi', line: 'EWL' },
    { station: 'Commonwealth', line: 'EWL' },
    { station: 'Dhoby Ghaut', line: 'NSL' },
    { station: 'Dover', line: 'EWL' },
    { station: 'Eunos', line: 'EWL' },
    { station: 'Expo', line: 'EWL' },
    { station: 'Gul Circle', line: 'EWL' },
    { station: 'Joo Koon', line: 'EWL' },
    { station: 'Jurong East', line: 'NSEWL' },
    { station: 'Kallang', line: 'EWL' },
    { station: 'Kembangan', line: 'EWL' },
    { station: 'Khatib', line: 'NSL' },
    { station: 'Kranji', line: 'NSL' },
    { station: 'Lakeside', line: 'EWL' },
    { station: 'Lavender', line: 'EWL' },
    { station: 'Marina Bay', line: 'NSL' },
    { station: 'Marina South Pier', line: 'NSL' },
    { station: 'Marsiling', line: 'NSL' },
    { station: 'Newton', line: 'NSL' },
    { station: 'Novena', line: 'NSL' },
    { station: 'Orchard', line: 'NSL' },
    { station: 'Outram Park', line: 'EWL' },
    { station: 'Pasir Ris', line: 'EWL' },
    { station: 'Paya Lebar', line: 'EWL' },
    { station: 'Pioneer', line: 'EWL' },
    { station: 'Queenstown', line: 'EWL' },
    { station: 'Raffles Place', line: 'NSEWL' },
    { station: 'Redhill', line: 'EWL' },
    { station: 'Sembawang', line: 'NSL' },
    { station: 'Simei', line: 'EWL' },
    { station: 'SMCR', line: 'NSEWL' },
    { station: 'Somerset', line: 'NSL' },
    { station: 'Tampines', line: 'EWL' },
    { station: 'Tanah Merah', line: 'EWL' },
    { station: 'Tanjong Pagar', line: 'EWL' },
    { station: 'Tiong Bahru', line: 'EWL' },
    { station: 'Toa Payoh', line: 'NSL' },
    { station: 'Tuas Crescent', line: 'EWL' },
    { station: 'Tuas Link', line: 'EWL' },
    { station: 'Tuas West Road', line: 'EWL' },
    { station: 'Woodlands', line: 'NSL' },
    { station: 'Yew Tee', line: 'NSL' },
    { station: 'Yio Chu Kang', line: 'NSL' },
    { station: 'Yishun', line: 'NSL' }
  ];
  let result = options.find(({ station }) => {
    return station.toLowerCase() === stationName.toLowerCase(); 
  })
  return get(result, 'line', 'NSEWL');
}